<template>
  <unity-page
    title="3D Pool Table"
    build-name="pool-table"
    :controls-data="[
      {
        control: 'WASD',
        action: 'Movement'
      },
      {
        control: 'Mouse (Hold)',
        action: 'Look'
      },
      {
        control: 'F',
        action: 'Strong'
      },
      {
        control: 'G',
        action: 'Normal'
      },
      {
        control: 'H',
        action: 'Weak'
      },
      {
        control: 'J',
        action: 'Weakest'
      }
    ]"
  >
    <ui-card-text>
      <div :class="$tt('body1')">
        I made this back at university in our second year. It was originally built in C++ using OpenGL. Recently I ported it over to Unity keeping as much logic as possible from the original (for better or worse!)
      </div>
    </ui-card-text>
  </unity-page>
</template>
