<template>
  <div v-if="material">
    <div class="field">
      <label>Name:</label>
      <ui-textfield
        v-model="name"
        @update:modelValue="onNameChange"
      >
        Name
      </ui-textfield>
    </div>

    <div class="field">
      <label>Ambient:</label>
      <ui-textfield
        v-model.number="material.material.ambient"
        @update:modelValue="onChange"
        input-type="number"
        step="0.01"
        min="0"
        max="1"
      >
        Ambient
      </ui-textfield>
    </div>

    <div class="field">
      <label>Diffuse:</label>
      <ui-textfield
        v-model.number="material.material.diffuse"
        @update:modelValue="onChange"
        input-type="number"
        step="0.01"
        min="0"
        max="1"
      >
        Diffuse
      </ui-textfield>
    </div>

    <div class="field">
      <label>Specular:</label>
      <ui-textfield
        v-model.number="material.material.specular"
        @update:modelValue="onChange"
        input-type="number"
        step="0.01"
        min="0"
        max="1"
      >
        Specular
      </ui-textfield>
    </div>

    <div class="field">
      <label>Shininess:</label>
      <ui-textfield
        v-model.number="material.material.shininess"
        @update:modelValue="onChange"
        input-type="number"
        step="0.01"
        min="0"
      >
        Shininess
      </ui-textfield>
    </div>

    <div class="field">
      <label>Reflective:</label>
      <ui-textfield
        v-model.number="material.material.reflective"
        @update:modelValue="onChange"
        input-type="number"
        step="0.01"
        min="0"
        max="1"
      >
        Reflective
      </ui-textfield>
    </div>

    <div class="field">
      <label>Transparency:</label>
      <ui-textfield
        v-model.number="material.material.transparency"
        @update:modelValue="onChange"
        input-type="number"
        step="0.01"
        min="0"
        max="1"
      >
        Transparency
      </ui-textfield>
    </div>

    <div class="field">
      <label>Refractive Index:</label>
      <ui-textfield
        v-model.number="material.material.refractiveIndex"
        @update:modelValue="onChange"
        input-type="number"
        step="0.01"
        min="1"
      >
        Refractive Index
      </ui-textfield>
    </div>

    <color-field
      :hex="color"
      name="Color"
      id="materialColor"
      @blur="onColorChange"
    />

    <div
      class="field"
      v-if="patterns"
    >
      <label>Pattern:</label>
      <ui-select
        v-model="material.material.patternName"
        :options="patternOptions"
        @update:modelValue="patternSelectionChanged"
        :default-value="null"
        :default-label="'No pattern'"
      >
        Pattern
      </ui-select>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { RayTracerMaterialWrapper } from './SceneModels'
import { hexToColor, colorToHex } from './Helpers'
import ColorField from './ColorField.vue'

class MaterialEditorProps {
  material!: RayTracerMaterialWrapper;
  patterns!: string[];
}

@Options({ components: { ColorField } })
export default class MaterialEditor extends Vue.with(MaterialEditorProps) {
  name = ''
  color = '';

  mounted (): void {
    this.$watch('material', () => {
      this.updateColor()
      if (this.material) {
        this.name = this.material.name
      }
    })
  }

  get patternOptions (): {label: string, value: string}[] {
    return [
      ...this.patterns.map(p => ({
        label: p,
        value: p
      }))
    ]
  }

  patternSelectionChanged (): void {
    this.onChange()
  }

  updateColor (): void {
    if (!this.material) {
      return
    }

    this.color = colorToHex(this.material.material.color)
  }

  onColorChange (value: string): void {
    this.color = value
    this.material.material.color = hexToColor(value)
  }

  onChange (): void {
    this.$emit('updated')
  }

  onNameChange (): void {
    this.$emit('nameChange', this.material.name, this.name)
    this.material.name = this.name
  }
}
</script>

<style lang="scss" scoped>
.field {
  display: grid;
  margin: 1em 0em;
  grid-template-columns: 2fr 2fr;
  text-align: left;
}

</style>
