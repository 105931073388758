
import { Options, Vue } from 'vue-class-component'
import { RayTracerSceneCamera } from './SceneModels'
import TransformEditor from './TransformEditor.vue'

class CameraEditorProps {
  camera!: RayTracerSceneCamera;
  width!: number;
  height!: number;
}

@Options({ components: { TransformEditor } })
export default class CameraEditor extends Vue.with(CameraEditorProps) {
  workingWidth = 1;
  workingHeight = 1;

  mounted (): void {
    this.$watch('width', this.updateDimensions)
    this.$watch('height', this.updateDimensions)

    this.updateDimensions()
  }

  updateDimensions (): void {
    this.workingWidth = this.width
    this.workingHeight = this.height
  }

  onChange (): void {
    this.$emit('updated', this.workingWidth, this.workingHeight)
  }
}
