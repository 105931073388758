<template>
  <div class="home">
    <ui-grid>
      <ui-grid-cell
        :columns="12"
        class="heading"
      >
        <ui-card>
          <ui-card-text :class="$tt('headline5')">
            Hey thanks for dropping by! 😄
          </ui-card-text>
          <ui-card-text :class="$tt('headline6')">
            My name's Nik, I'm a web developer but I like making the odd Unity app/game in my spare time
          </ui-card-text>
          <ui-card-text :class="$tt('headline6')">
            Why not check one of them out below?
          </ui-card-text>
        </ui-card>
      </ui-grid-cell>
      <ui-grid-cell
        v-for="project of projects"
        :key="project.name"
      >
        <ui-card
          class="project-card"
          v-shadow.transition="[2, 8]"
        >
          <router-link :to="'/unity/' + project.path">
            <ui-card-content :class="project.path">
              <ui-card-media
                square
                class="project-card__media"
              >
                <ui-card-media-content class="project-card__media-content" />
                <ui-card-media-content class="project-card__media-content--with-title">
                  <div :class="[$tt('headline5'), 'project-card__media-title']">
                    {{ project.name }}
                  </div>
                </ui-card-media-content>
              </ui-card-media>
            </ui-card-content>
          </router-link>
        </ui-card>
      </ui-grid-cell>
    </ui-grid>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

interface ProjectEntry {
  name: string;
  path: string;
}

@Options({
})
export default class Home extends Vue {
  projects: ProjectEntry[] = [
    {
      name: 'Block Modelling Tool',
      path: 'block-modelling'
    },
    {
      name: '3D Pool Table',
      path: 'pool-table'
    },
    {
      name: 'Shader Project - Teapot Wars',
      path: 'teapot-wars'
    }
  ]
}
</script>

<style lang="scss" scoped>
.heading {
    display: flex;
    justify-content: center;
    align-content: center;
}

.project-card__media-content {
  content: "";
  color: transparent;
  width: 100%;
  height: 100%;
  background-color: black;
  background-position: center;
  background-size: cover;
  transition: all .5s;

  .block-modelling & {
    background-image: url('../../public/images/previews/block-modelling.png');
  }

  .pool-table & {
    background-image: url('../../public/images/previews/pool-table.png');
  }

  .teapot-wars & {
    background-image: url('../../public/images/previews/teapot-wars.png');
  }
}

.project-card:hover .project-card__media-content {
    transform: scale(1.025);
}

.project-card__media-content--with-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.project-card__media-title {
  padding: 8px 16px;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  color: white;
}
</style>
