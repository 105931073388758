<template>
  <div v-html="compiledMarkdown" />
</template>

<script lang="ts">
import { prop, Vue } from 'vue-class-component'
import marked from 'marked'
import { Slot } from '@vue/runtime-core'

class MarkdownProps {
  source? = prop<string>({ default: '' })
}

export default class Markdown extends Vue.with(MarkdownProps) {
  value = ''

  get compiledMarkdown (): string {
    return marked(this.value, { sanitize: true })
  }

  mounted (): void {
    const defaultSlot = this.$slots.default as Slot
    if (defaultSlot()[0].children) {
      this.value = defaultSlot()[0].children as never
    } else {
      this.value = this.source as never
    }
  }
}
</script>
