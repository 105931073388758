<template>
  <unity-page
    title="Shaders Project - Teapot Wars"
    build-name="teapot-wars"
    :controls-data="[
      {
        control: 'W',
        action: 'Up'
      },
      {
        control: 'A',
        action: 'Left'
      },
      {
        control: 'S',
        action: 'Down'
      },
      {
        control: 'D',
        action: 'Right'
      },
      {
        control: 'Q',
        action: 'Zoom Out'
      },
      {
        control: 'E',
        action: 'Zoom In'
      },
      {
        control: 'Click',
        action: 'Toggle Auto Camera'
      }
    ]"
  >
    <ui-card-text>
      <div :class="$tt('body1')">
        The original project was written using GLSL using RenderMonkey and I'm porting over each effect into Unity... It's a pretty interesting experience!

        It's very much WIP at the moment and has about 4/26 of the original effects implemented. I'll upload new versions when I add more effects 😄
      </div>
    </ui-card-text>
  </unity-page>
</template>
