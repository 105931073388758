

import { Vue, Options } from 'vue-class-component'
import ChatItem from './ChatItem.vue'
import ChatInput from './ChatInput.vue'
import { EntryAddedMessage, ParticipantInfo } from './ChatMessage'

class ChatComponentProps {
  messages!: EntryAddedMessage[];
  userName!: string;
  typing!: ParticipantInfo[];
}

@Options({
  components: {
    ChatItem,
    ChatInput
  }
})
export default class ChatComponent extends Vue.with(ChatComponentProps) {
  declare $refs: {
    messagesContainer: HTMLElement
  }

  created (): void {
    this.$watch('messages', () => {
      this.$refs.messagesContainer.scrollTop = 0
    })
  }

  sendMessage (value: string): void {
    this.$emit('sendMessage', value)
  }

  onTyping (): void {
    this.$emit('typing')
  }
}
