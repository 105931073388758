
import { Options, Vue } from 'vue-class-component'

interface ProjectEntry {
  name: string;
  path: string;
}

@Options({
})
export default class Home extends Vue {
  projects: ProjectEntry[] = [
    {
      name: 'Block Modelling Tool',
      path: 'block-modelling'
    },
    {
      name: '3D Pool Table',
      path: 'pool-table'
    },
    {
      name: 'Shader Project - Teapot Wars',
      path: 'teapot-wars'
    }
  ]
}
