

import { Vue, Options } from 'vue-class-component'
import { EntryAddedMessage } from './ChatMessage'

class ChatItemProps {
  message!: EntryAddedMessage;
  userName!: string;
}

@Options({
  components: {
  }
})
export default class ChatItem extends Vue.with(ChatItemProps) {
}
