import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import UnityView from './components/UnityView.vue'
import Markdown from './components/Markdown.vue'
import Container from './components/Container.vue'
import Item from './components/Item.vue'

import UnityPage from './views/unity/UnityPage.vue'

import BalmUI from 'balm-ui' // Official Google Material Components
import BalmUIPlus from 'balm-ui-plus' // BalmJS Team Material Components

import './styles.scss'

createApp(App)

  .use(store)
  .use(router)

  .use(BalmUI)
  .use(BalmUIPlus)

  .component('UnityView', UnityView)
  .component('Markdown', Markdown)
  .component('Container', Container)
  .component('Item', Item)

  .component('UnityPage', UnityPage)

  .mount('#app')
