<template>
  <div class="full-height chat">
    <div
      class="chat--messages"
      ref="messagesContainer"
    >
      <chat-item
        v-for="message of messages"
        :key="message.value"
        :message="message"
        :user-name="userName"
      />
    </div>

    <chat-input
      @send-message="sendMessage"
      @typing="onTyping"
      :typing="typing"
    />
  </div>
</template>

<script lang="ts">

import { Vue, Options } from 'vue-class-component'
import ChatItem from './ChatItem.vue'
import ChatInput from './ChatInput.vue'
import { EntryAddedMessage, ParticipantInfo } from './ChatMessage'

class ChatComponentProps {
  messages!: EntryAddedMessage[];
  userName!: string;
  typing!: ParticipantInfo[];
}

@Options({
  components: {
    ChatItem,
    ChatInput
  }
})
export default class ChatComponent extends Vue.with(ChatComponentProps) {
  declare $refs: {
    messagesContainer: HTMLElement
  }

  created (): void {
    this.$watch('messages', () => {
      this.$refs.messagesContainer.scrollTop = 0
    })
  }

  sendMessage (value: string): void {
    this.$emit('sendMessage', value)
  }

  onTyping (): void {
    this.$emit('typing')
  }
}
</script>

<style lang="scss" scoped>

.chat {
    display: flex;
    flex-direction: column;
    min-width: 320px;

  &--messages {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    padding: 0.5em;
  }
}
</style>
