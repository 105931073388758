<template>
  <div class="grid">
    <template v-if="position">
      <label>Position:</label>
      <ui-textfield
        v-model.number="position.x"
        input-type="number"
        @update:modelValue="onChange"
        step="0.1"
      >
        x
      </ui-textfield>
      <ui-textfield
        v-model.number="position.y"
        input-type="number"
        @update:modelValue="onChange"
        step="0.1"
      >
        y
      </ui-textfield>
      <ui-textfield
        v-model.number="position.z"
        input-type="number"
        @update:modelValue="onChange"
        step="0.1"
      >
        z
      </ui-textfield>
    </template>

    <template v-if="rotation">
      <label>Rotation:</label>
      <ui-textfield
        v-model.number="rotation.x"
        input-type="number"
        @update:modelValue="onChange"
        step="1"
      >
        x
      </ui-textfield>
      <ui-textfield
        v-model.number="rotation.y"
        input-type="number"
        @update:modelValue="onChange"
        step="1"
      >
        y
      </ui-textfield>
      <ui-textfield
        v-model.number="rotation.z"
        input-type="number"
        @update:modelValue="onChange"
        step="1"
      >
        z
      </ui-textfield>
    </template>
    <template v-if="scale">
      <label>Scale:</label>
      <ui-textfield
        v-model.number="scale.x"
        input-type="number"
        @update:modelValue="onChange"
        step="0.01"
      >
        x
      </ui-textfield>
      <ui-textfield
        v-model.number="scale.y"
        input-type="number"
        @update:modelValue="onChange"
        step="0.01"
      >
        y
      </ui-textfield>
      <ui-textfield
        v-model.number="scale.z"
        input-type="number"
        @update:modelValue="onChange"
        step="0.01"
      >
        z
      </ui-textfield>
    </template>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Vector3 } from './SceneModels'

class TransformEditorProps {
  position?: Vector3;
  rotation?: Vector3;
  scale?: Vector3;
}

@Options({})
export default class TransformEditor extends Vue.with(TransformEditorProps) {
  onChange (): void {
    this.$emit('change')
  }
}
</script>

<style lang="scss" scoped>

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 1em;
  text-align: left;
}

</style>
