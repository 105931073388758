<template>
  <div>
    <ui-grid class="full-page-height">
      <ui-grid-cell>
        <item>
          <ui-card>
            <ui-tabs v-model="activeTab">
              <ui-tab>Host</ui-tab>
              <ui-tab>Client</ui-tab>
            </ui-tabs>

            <ui-panels v-model="activeTab">
              <ui-panel>
                <rtc-host
                  @self-client-created="clientConnected"
                  @client-connected="hostClientConnected"
                  @client-disconnected="hostClientDisconnected"
                />
              </ui-panel>
              <ui-panel>
                <rtc-client
                  @connected="clientConnected"
                />
              </ui-panel>
            </ui-panels>
          </ui-card>
        </item>

        <item>
          <ui-card>
            <container class="participants">
              <div :class="$tt('headline6')">
                Participants
              </div>

              <ui-divider />

              <div
                :class="$tt('subtitle2')"
                v-if="!participants.length"
              >
                <br>
                Nobody is here yet, connect a client using the host interface in one tab and the client interface in another
                <br>
                <br>
                Also you can press the "CONNECT TO SELF" button to also join to the room you're hosting
              </div>

              <ui-list>
                <ui-item
                  v-for="participant of participants"
                  :key="participant.name"
                >
                  <ui-item-first-content>
                    <ui-icon>{{ participant.isConnected ? 'phone_enabled' : 'phone_disabled' }}</ui-icon>
                  </ui-item-first-content>
                  <ui-item-text-content>{{ participant.name }}</ui-item-text-content>
                </ui-item>
              </ui-list>
            </container>
          </ui-card>
        </item>
      </ui-grid-cell>

      <ui-grid-cell
        :columns="{ desktop: 5, tablet: 8, mobile: 12 }"
        class="chat-container"
      >
        <ui-card class="full-height">
          <chat-component
            :class="client ? '' : 'generic-disabled'"
            :messages="sortedMessages"
            @send-message="sendMessage"
            :user-name="client?.name"
            @typing="onTyping"
            :typing="currentlyTyping"
          />
        </ui-card>
      </ui-grid-cell>
    </ui-grid>
  </div>
</template>

<script lang="ts">

import { Vue, Options } from 'vue-class-component'
import WebRTCHostComponent from '@/components/WebRTC/WebRTCHostComponent.vue'
import WebRTCClientComponent from '@/components/WebRTC/WebRTCClientComponent.vue'
import { WebRTCClient } from '@/data/webrtc/WebRTCClient'
import sanitizeHtml from 'sanitize-html'
import { AnyChatMessage, EntryAddedMessage, MessageType, ParticipantInfo } from '@/components/chat/ChatMessage'
import ChatComponent from '@/components/chat/ChatComponent.vue'
import Container from '@/components/Container.vue'
import Item from '@/components/Item.vue'
import { WebRTCHost } from '@/data/webrtc/WebRTCHost'

interface CurrentlyTyping {
  clear: number;
  participant: ParticipantInfo;
}

@Options({
  components: {
    rtcHost: WebRTCHostComponent,
    rtcClient: WebRTCClientComponent,
    ChatComponent,
    Container
  },
  Item
})
export default class WebRTCTest extends Vue {
  client: WebRTCClient<AnyChatMessage> | null = null
  receivedMessages: EntryAddedMessage[] = []

  activeTab = 0

  participants: ParticipantInfo[] = []
  clients: ParticipantInfo[] = []

  participantsTyping: CurrentlyTyping[] = []
  get currentlyTyping (): ParticipantInfo[] {
    return this.participantsTyping.filter(p => p.participant.name !== this.client?.name).map(p => p.participant)
  }

  get sortedMessages (): EntryAddedMessage[] {
    return this.receivedMessages.slice().reverse()
  }

  hostClientConnected (_: RTCDataChannel, clientName: string, host: WebRTCHost<AnyChatMessage>): void {
    const client = this.clients.find(c => c.name === clientName)
    if (client) {
      client.isConnected = true
    } else {
      this.clients.push({
        name: clientName as string,
        isConnected: true
      })
    }

    host.sendMessage({
      type: MessageType.ParticipantsChanged,
      participants: this.clients
    })
  }

  hostClientDisconnected (_: RTCDataChannel, clientName: string, host: WebRTCHost<AnyChatMessage>): void {
    const client = this.clients.find(c => c.name === clientName)
    if (client) {
      client.isConnected = false
    }

    host.sendMessage({
      type: MessageType.ParticipantsChanged,
      participants: this.clients
    })
  }

  onNewMessage (message: AnyChatMessage): void {
    if (message.type === MessageType.EntryAdded) {
      message.value = sanitizeHtml(message.value)
      this.receivedMessages.push(message)
    } else if (message.type === MessageType.ParticipantsChanged) {
      this.participants = message.participants
    } else if (message.type === MessageType.ParticipantTyping) {
      let existing = this.participantsTyping.find(p => p.participant.name === message.participant.name)

      if (existing) {
        clearTimeout(existing.clear)
      } else {
        existing = {
          participant: message.participant,
          clear: 0
        }
        this.participantsTyping.push(existing)
      }

      existing.clear = setTimeout(() => {
        const index = this.participantsTyping.findIndex(p => p.participant.name === message.participant.name)
        if (index !== -1) {
          this.participantsTyping.splice(index, 1);
          (existing as CurrentlyTyping).clear = 0
        }
      }, 2500)
    }
  }

  sendMessage (value: string): void {
    const newMessage: EntryAddedMessage = {
      value,
      type: MessageType.EntryAdded,
      metaData: {
        sender: this.client?.name as string,
        timeStamp: new Date().toUTCString()
      }
    }

    if (this.client) {
      this.client.sendMessage(newMessage)
    } else {
      throw new Error('No client available')
    }
  }

  clientConnected (client: WebRTCClient<AnyChatMessage>): void {
    this.client = client

    client.onMessage = this.onNewMessage
  }

  onTyping (): void {
    if (!this.client || !this.client.name) {
      return
    }

    this.client.sendMessage({
      type: MessageType.ParticipantTyping,
      participant: {
        name: this.client.name,
        isConnected: true
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.full-page-height {
    height: calc(100vh - 75px);
}

.chat-container {
  overflow: hidden;
}

.participants {
  padding: auto;
}
</style>
