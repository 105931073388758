<template>
  <div v-if="pattern">
    <div class="field">
      <label>Name:</label>
      <ui-textfield
        v-model="name"
        @update:modelValue="onNameChange"
      >
        Name
      </ui-textfield>
    </div>

    <div class="field">
      <label>Type:</label>
      <ui-select
        v-model="pattern.pattern.type"
        :options="typeOptions"
        @update:modelValue="onTypeChange"
      >
        Type
      </ui-select>
    </div>

    <transform-editor
      :position="pattern.pattern.position"
      :rotation="pattern.pattern.rotation"
      :scale="pattern.pattern.scale"
      @change="onChange"
    />

    <div
      v-for="(patternName, index) of pattern.pattern.patternNames"
      :key="patternName"
    >
      <color-field
        :hex="patternName"
        :name="'Color ' + index"
        @blur="onPatternNameChange($event, index)"
      />
    </div>

    <ui-button
      v-if="maxPatternCount === 0 || pattern.pattern.patternNames.length < maxPatternCount"
      @click="addPattern"
    >
      Add subpattern
    </ui-button>

    <!-- <div class="field">
      <label>Ambient:</label>
      <ui-textfield
        v-model.number="pattern.pattern.ambient"
        @update:modelValue="onChange"
        input-type="number"
        step="0.01"
        min="0"
        max="1"
      >
        Ambient
      </ui-textfield>
    </div> -->

    <!-- <div class="color-field">
      <label for="pointLightColor">Color:</label>
      <div class="color-picker">
        <input
          type="color"
          id="pointLightColor"
          name="pointLightColor"
          v-model="color"
          @update:modelValue="onColorChange"
        >
      </div>

      <ui-textfield
        v-model="color"
        @update:modelValue="onColorChange"
      >
        Color
      </ui-textfield>
    </div> -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { RayTracerPatternType, RayTracerPatternUpdate } from './SceneModels'
import TransformEditor from './TransformEditor.vue'
import ColorField from './ColorField.vue'
import { debounce } from 'lodash'

class PatternEditorProps {
  pattern!: RayTracerPatternUpdate;
}

@Options({ components: { TransformEditor, ColorField } })
export default class PatternEditor extends Vue.with(PatternEditorProps) {
  name = ''

  onChangeDebounced = debounce(this.onChange, 1000)

  typeOptions: {label: string, value: RayTracerPatternType}[] = [
    { label: 'Blended', value: 'Blended' },
    { label: 'Checkers', value: 'Checkers' },
    { label: 'Gradient', value: 'Gradient' },
    { label: 'Looping Gradient', value: 'LoopingGradient' },
    { label: 'Ring', value: 'Ring' },
    { label: 'Stripe', value: 'Stripe' },
    { label: 'Perturbed', value: 'Perturbed' }
  ];

  mounted (): void {
    this.$watch('pattern', () => {
      if (this.pattern) {
        this.name = this.pattern.name
      }
    })
  }

  onTypeChange (): void {
    console.log(this.pattern.pattern.type)
    const min = this.minPatternCount
    const max = this.maxPatternCount

    if (this.pattern.pattern.patternNames.length < min) {
      for (let i = 0; i < min - this.pattern.pattern.patternNames.length; i++) {
        this.pattern.pattern.patternNames.push('#fff')
      }
    }

    if (this.pattern.pattern.patternNames.length > max) {
      this.pattern.pattern.patternNames.splice(this.pattern.pattern.patternNames.length - max)
    }

    this.onChange()
  }

  get minPatternCount (): number {
    switch (this.pattern.pattern.type) {
      case 'Blended':
        return 2
      case 'Checkers':
        return 2
      case 'Gradient':
        return 2
      case 'LoopingGradient':
        return 2
      case 'Ring':
        return 2
      case 'Stripe':
        return 2
      case 'Perturbed':
        return 1
    }
  }

  get maxPatternCount (): number {
    switch (this.pattern.pattern.type) {
      case 'Blended':
        return 0
      case 'Checkers':
        return 2
      case 'Gradient':
        return 2
      case 'LoopingGradient':
        return 0
      case 'Ring':
        return 0
      case 'Stripe':
        return 0
      case 'Perturbed':
        return 1
    }
  }

  addPattern (): void {
    this.pattern.pattern.patternNames.push('#fff')
  }

  onPatternNameChange (newName: string, index: number): void {
    this.pattern.pattern.patternNames[index] = newName
    this.onChangeDebounced()
  }

  onChange (): void {
    this.$emit('updated')
  }

  onNameChange (): void {
    this.$emit('nameChange', this.pattern.name, this.name)
    this.pattern.name = this.name
  }
}
</script>

<style lang="scss" scoped>
.field {
  display: grid;
  margin: 1em 0em;
  grid-template-columns: 2fr 2fr;
  text-align: left;
}

.color-field {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  margin: 1em 0em;
  text-align: left;
}

.color-picker {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
