
import { Options, Vue } from 'vue-class-component'
import { PatternStripe, RayTracerPatternUpdate } from './SceneModels'
import PatternEditor from './PatternEditor.vue'

class PatternListProps {
  patterns!: RayTracerPatternUpdate[];
  usedPatterns!: string[];
}

@Options({ components: { PatternEditor } })
export default class PatternList extends Vue.with(PatternListProps) {
  selectedIndex = -1;

  onChange (): void {
    this.$emit('patternUpdated', this.selectedIndex)
  }

  selectionChange (index: number): void {
    if (this.selectedIndex === index) {
      this.selectedIndex = -1
    } else {
      this.selectedIndex = index
      this.$emit('selectionChange', this.selectedIndex)
    }
  }

  removePattern (index: number): void {
    this.patterns.splice(index, 1)
    this.selectedIndex = -1
    this.$emit('patternsUpdated')
  }

  addPattern (): void {
    this.patterns.push({
      name: 'New Pattern',
      pattern: {
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: { x: 1, y: 1, z: 1 },
        patternNames: ['#fff', '#000'],
        type: 'Stripe'
      } as PatternStripe
    })
    this.selectedIndex = this.patterns.length - 1
    this.$emit('patternsUpdated')
  }

  onNameChange (previous: string, newName: string): void {
    this.$emit('patternNameChange', previous, newName)
  }
}
