export enum MessageType {
  EntryAdded,
  ParticipantsChanged,
  ParticipantTyping
}
export interface EntryAddedMessage {
  type: MessageType.EntryAdded;
  value: string;
  metaData: {
    sender: string;
    timeStamp: string;
  }
}

export interface ParticipantInfo {
  name: string;
  isConnected: boolean;
}

export interface ParticipantsChangedMessage {
  type: MessageType.ParticipantsChanged;
  participants: ParticipantInfo[];
}

export interface ParticipantTypingMessage {
  type: MessageType.ParticipantTyping;
  participant: ParticipantInfo;
}

export type AnyChatMessage = EntryAddedMessage | ParticipantsChangedMessage | ParticipantTypingMessage;
