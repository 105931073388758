<template>
  <div
    :class="['chat-message', message.metaData.sender === userName ? 'chat-message__mine' : '']"
  >
    <div
      class="chat-message--content mdc-elevation--z16"
      v-shape
    >
      <div class="chat-message--meta">
        <span :class="$tt('subtitle1')">{{ message.metaData.sender === userName ? 'You' : message.metaData.sender }}</span>
      </div>
      <ui-divider />
      <div
        class="chat-message--value"
        v-html="message.value"
      />
    </div>
    <span :class="$tt('caption')">{{ message.metaData.timeStamp }}</span>
  </div>
</template>

<script lang="ts">

import { Vue, Options } from 'vue-class-component'
import { EntryAddedMessage } from './ChatMessage'

class ChatItemProps {
  message!: EntryAddedMessage;
  userName!: string;
}

@Options({
  components: {
  }
})
export default class ChatItem extends Vue.with(ChatItemProps) {
}
</script>

<style lang="scss" scoped>
.chat-message {
  margin-bottom: 1em;
  flex-direction: column;
  align-items: flex-end;
  text-align: left;

  &--content {
      width: 75%;
  }

  &--meta {
    padding-right: 0.5em;
    text-align: end;
    display: flex;
    flex-direction: column;
  }

  &__mine {
    display: flex;
    justify-content: flex-end;
  }

  &--value {
      padding: 0.5em;
  }
}
</style>

<style lang="scss">
  .chat-message--value * {
    margin: 0;
        text-align: left;
  }
</style>
