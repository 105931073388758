export class WebRTCIceServersProvider {
  getIceServers (): Promise<RTCIceServer[]> {
    const iceServers: RTCIceServer[] = [
      {
        urls: 'stun:stun.l.google.com:19302'
      }
    ]

    return Promise.resolve(iceServers)
  }
}
