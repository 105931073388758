
import { Options, Vue } from 'vue-class-component'
import { RayTracerMaterialWrapper } from './SceneModels'
import MaterialEditor from './MaterialEditor.vue'

class MaterialListProps {
  materials!: RayTracerMaterialWrapper[];
  usedMaterials!: string[];
  patterns!: string[];
}

@Options({ components: { MaterialEditor } })
export default class MaterialList extends Vue.with(MaterialListProps) {
  selectedIndex = -1;

  onChange (): void {
    this.$emit('materialUpdated', this.selectedIndex)
  }

  selectionChange (index: number): void {
    if (this.selectedIndex === index) {
      this.selectedIndex = -1
    } else {
      this.selectedIndex = index
      this.$emit('selectionChange', this.selectedIndex)
    }
  }

  removeMaterial (index: number): void {
    this.materials.splice(index, 1)
    this.selectedIndex = -1
    this.$emit('materialsUpdated')
  }

  addMaterial (): void {
    this.materials.push({
      name: 'New Material',
      material: {
        color: { r: 1, g: 1, b: 1, a: 1 },
        ambient: 0.1,
        diffuse: 0.9,
        specular: 0.9,
        shininess: 200.0,
        reflective: 0.0,
        transparency: 0.0,
        refractiveIndex: 1,
        patternName: null
      }
    })
    this.selectedIndex = this.materials.length - 1
    this.$emit('materialsUpdated')
  }

  onNameChange (previous: string, newName: string): void {
    this.$emit('materialNameChange', previous, newName)
  }
}
