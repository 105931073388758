<template>
  <div>
    <div
      class="show-info-button"
      v-if="infoHidden"
    >
      <ui-icon-button
        icon="chevron_right"
        v-tooltip="'Show info section'"
        aria-describedby="show-unity-info-tooltip"
        @click="showInfo()"
      />
    </div>
    <ui-grid>
      <ui-grid-cell
        v-if="!infoHidden"
        :columns="{ desktop: 3, mobile: 4 }"
      >
        <item>
          <ui-card>
            <ui-card-text>
              <div :class="$tt('headline5')">
                {{ title }}
              </div>
              <div
                :class="$tt('subtitle2')"
                v-if="subtitle"
              >
                {{ subtitle }}
              </div>
            </ui-card-text>

            <slot />

            <ui-card-text v-if="controlsData?.length">
              <div :class="$tt('headline6')">
                Controls
              </div>
              <ui-table
                :data="controlsData"
                :thead="controlsHeadings"
                :tbody="controlsColumns"
                fullwidth
              />
            </ui-card-text>
            <ui-card-actions>
              <ui-card-icons>
                <ui-icon-button
                  icon="chevron_left"
                  v-tooltip="'Hide info section'"
                  aria-describedby="hide-unity-info-tooltip"
                  @click="hideInfo()"
                />

                <ui-icon-button
                  icon="fullscreen"
                  v-tooltip="'Make Unity window fullscreen'"
                  aria-describedby="unity-fullscreen-tooltip"
                  @click="switchToFullScreen()"
                />
              </ui-card-icons>
            </ui-card-actions>
          </ui-card>
        </item>
      </ui-grid-cell>

      <ui-grid-cell :columns="{ desktop: infoHidden ? 12 : 9, mobile: 12 }">
        <unity-view
          :build-name="buildName"
          @created="onCreated"
          @resize="onResize"
          :width="width"
          :height="height"
        />
      </ui-grid-cell>
    </ui-grid>
  </div>
</template>

<style lang="scss" scoped>
.controls-table {
  background-color: transparent;
  width: 100%;
}

.show-info-button {
  position: absolute;
}
</style>

<script lang="ts">
import { Options, prop, Vue } from 'vue-class-component'
import { UnityInstance, UnityWindow } from '@/data/unity/UnityInstance'

export interface ControlEntry {
  control: string;
  action: string;
}

class UnityPageProps {
  buildName!: string;
  title!: string;
  subtitle?: string;
  controlsData?: ControlEntry[];
  controlsHeadings = prop<string[]>({
    default: [
      'Control',
      'Action'
    ]
  });

  controlsColumns = prop<string[]>({
    default: [
      'control',
      'action'
    ]
  });

  width = prop<number>({ default: 960 });
  height = prop<number>({ default: 600 });
}

@Options({})
export default class UnityPage extends Vue.with(UnityPageProps) {
  infoHidden = false

  switchToFullScreen (): void {
    (window as UnityWindow).unityInstance.switchToFullscreen()
  }

  hideInfo (): void {
    this.infoHidden = true
    this.forceResize()
  }

  showInfo (): void {
    this.infoHidden = false
    this.forceResize()
  }

  forceResize (): void {
    setTimeout(() => (window as UnityWindow).unityInstance.forceResize(), 500)
  }

  onResize (): void {
    this.$emit('resize')
  }

  onCreated (unityInstance: UnityInstance): void {
    this.$emit('created', unityInstance)
  }
}
</script>
