<template>
  <div>
    <transform-editor
      :position="camera.position"
      :rotation="camera.rotation"
      @change="onChange"
    />

    <div class="field">
      <label>Field of View:</label>
      <ui-textfield
        v-model.number="camera.fieldOfView"
        input-type="number"
        @update:modelValue="onChange"
        step="1"
      >
        Field of View
      </ui-textfield>
    </div>

    <div class="field">
      <label>Width:</label>
      <ui-textfield
        v-model.number="workingWidth"
        input-type="number"
        @update:modelValue="onChange"
        step="1"
        min="1"
        max="2048"
      >
        Width
      </ui-textfield>
    </div>

    <div class="field">
      <label>Height:</label>
      <ui-textfield
        v-model.number="workingHeight"
        input-type="number"
        @update:modelValue="onChange"
        step="1"
        min="1"
        max="2048"
      >
        Height
      </ui-textfield>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { RayTracerSceneCamera } from './SceneModels'
import TransformEditor from './TransformEditor.vue'

class CameraEditorProps {
  camera!: RayTracerSceneCamera;
  width!: number;
  height!: number;
}

@Options({ components: { TransformEditor } })
export default class CameraEditor extends Vue.with(CameraEditorProps) {
  workingWidth = 1;
  workingHeight = 1;

  mounted (): void {
    this.$watch('width', this.updateDimensions)
    this.$watch('height', this.updateDimensions)

    this.updateDimensions()
  }

  updateDimensions (): void {
    this.workingWidth = this.width
    this.workingHeight = this.height
  }

  onChange (): void {
    this.$emit('updated', this.workingWidth, this.workingHeight)
  }
}
</script>

<style lang="scss" scoped>
.field {
  display: grid;
  margin: 1em 0em;
  grid-template-columns: 1fr 3fr;
  text-align: left;
}
</style>
