

import { Vue, Options } from 'vue-class-component'
import WebRTCHostComponent from '@/components/WebRTC/WebRTCHostComponent.vue'
import WebRTCClientComponent from '@/components/WebRTC/WebRTCClientComponent.vue'
import { WebRTCClient } from '@/data/webrtc/WebRTCClient'
import sanitizeHtml from 'sanitize-html'
import { AnyChatMessage, EntryAddedMessage, MessageType, ParticipantInfo } from '@/components/chat/ChatMessage'
import ChatComponent from '@/components/chat/ChatComponent.vue'
import Container from '@/components/Container.vue'
import Item from '@/components/Item.vue'
import { WebRTCHost } from '@/data/webrtc/WebRTCHost'

interface CurrentlyTyping {
  clear: number;
  participant: ParticipantInfo;
}

@Options({
  components: {
    rtcHost: WebRTCHostComponent,
    rtcClient: WebRTCClientComponent,
    ChatComponent,
    Container
  },
  Item
})
export default class WebRTCTest extends Vue {
  client: WebRTCClient<AnyChatMessage> | null = null
  receivedMessages: EntryAddedMessage[] = []

  activeTab = 0

  participants: ParticipantInfo[] = []
  clients: ParticipantInfo[] = []

  participantsTyping: CurrentlyTyping[] = []
  get currentlyTyping (): ParticipantInfo[] {
    return this.participantsTyping.filter(p => p.participant.name !== this.client?.name).map(p => p.participant)
  }

  get sortedMessages (): EntryAddedMessage[] {
    return this.receivedMessages.slice().reverse()
  }

  hostClientConnected (_: RTCDataChannel, clientName: string, host: WebRTCHost<AnyChatMessage>): void {
    const client = this.clients.find(c => c.name === clientName)
    if (client) {
      client.isConnected = true
    } else {
      this.clients.push({
        name: clientName as string,
        isConnected: true
      })
    }

    host.sendMessage({
      type: MessageType.ParticipantsChanged,
      participants: this.clients
    })
  }

  hostClientDisconnected (_: RTCDataChannel, clientName: string, host: WebRTCHost<AnyChatMessage>): void {
    const client = this.clients.find(c => c.name === clientName)
    if (client) {
      client.isConnected = false
    }

    host.sendMessage({
      type: MessageType.ParticipantsChanged,
      participants: this.clients
    })
  }

  onNewMessage (message: AnyChatMessage): void {
    if (message.type === MessageType.EntryAdded) {
      message.value = sanitizeHtml(message.value)
      this.receivedMessages.push(message)
    } else if (message.type === MessageType.ParticipantsChanged) {
      this.participants = message.participants
    } else if (message.type === MessageType.ParticipantTyping) {
      let existing = this.participantsTyping.find(p => p.participant.name === message.participant.name)

      if (existing) {
        clearTimeout(existing.clear)
      } else {
        existing = {
          participant: message.participant,
          clear: 0
        }
        this.participantsTyping.push(existing)
      }

      existing.clear = setTimeout(() => {
        const index = this.participantsTyping.findIndex(p => p.participant.name === message.participant.name)
        if (index !== -1) {
          this.participantsTyping.splice(index, 1);
          (existing as CurrentlyTyping).clear = 0
        }
      }, 2500)
    }
  }

  sendMessage (value: string): void {
    const newMessage: EntryAddedMessage = {
      value,
      type: MessageType.EntryAdded,
      metaData: {
        sender: this.client?.name as string,
        timeStamp: new Date().toUTCString()
      }
    }

    if (this.client) {
      this.client.sendMessage(newMessage)
    } else {
      throw new Error('No client available')
    }
  }

  clientConnected (client: WebRTCClient<AnyChatMessage>): void {
    this.client = client

    client.onMessage = this.onNewMessage
  }

  onTyping (): void {
    if (!this.client || !this.client.name) {
      return
    }

    this.client.sendMessage({
      type: MessageType.ParticipantTyping,
      participant: {
        name: this.client.name,
        isConnected: true
      }
    })
  }
}
