import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

import BlockModelling from '../views/unity/BlockModelling.vue'
import PoolTable from '../views/unity/PoolTable.vue'
import TeapotWars from '../views/unity/TeapotWars.vue'
import RayTracerChallenge from '../views/unity/RayTracerChallenge.vue'

import WebRTCTest from '../views/projects/WebRTCTest.vue'
import WebRTCChat from '../views/projects/WebRTCChat.vue'

function createUnityRoute<T> (name: string, filename: string, component: T, hidden = false): RouteRecordRaw {
  return {
    path: `/unity/${filename}`,
    name: name,
    component,
    meta: {
      parent: 'Unity Projects',
      hidden
    }
  }
}

function createWebRoute<T> (name: string, path: string, component: T): RouteRecordRaw {
  return {
    path: `/webprojects/${path}`,
    name: name,
    component,
    meta: {
      parent: 'Web Projects'
    }
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'About Me',
    component: Home
  },
  createUnityRoute('Block Modelling', 'block-modelling', BlockModelling),
  createUnityRoute('Pool Table', 'pool-table', PoolTable),
  createUnityRoute('Teapot Wars', 'teapot-wars', TeapotWars),
  createUnityRoute('Ray Tracer Challenge', 'ray-tracer-challenge', RayTracerChallenge, true),
  // createWebRoute('WebRTC Testing', 'webrtctest', WebRTCTest),
  createWebRoute('WebRTC Chat', 'webrtcchat', WebRTCChat),
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
