<template>
  <ui-button
    :type="2"
    icon="person"
    @click="openLogin()"
  >
    Login
  </ui-button>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import * as netlifyIdentity from 'netlify-identity-widget'

@Options({ components: { } })
export default class LoginButton extends Vue {
  mounted (): void {
    netlifyIdentity.init()
    netlifyIdentity.on('init', user => console.log('init', user))
    netlifyIdentity.on('login', user => console.log('login', user))
    netlifyIdentity.on('logout', () => console.log('Logged out'))
    netlifyIdentity.on('error', err => console.error('Error', err))
    netlifyIdentity.on('open', () => console.log('Widget opened'))
    netlifyIdentity.on('close', () => console.log('Widget closed'))
  }

  unmounted (): void {
    netlifyIdentity.off('init') // to unbind all registered handlers
    netlifyIdentity.off('login') // to unbind all registered handlers
    netlifyIdentity.off('logout') // to unbind all registered handlers
    netlifyIdentity.off('error') // to unbind all registered handlers
    netlifyIdentity.off('open') // to unbind all registered handlers
    netlifyIdentity.off('close') // to unbind all registered handlers
  }

  openLogin (): void {
    netlifyIdentity.open('login') // open the modal to the login tab
  }
}

</script>
