import { hexToRgba, rgbaToHex } from 'hex-and-rgba'
import { Color } from './SceneModels'

export function isValidHex (hex: string): boolean {
  return !!hexToRgba(hex)
}

export function hexToColor (hex: string): Color {
  const [r, g, b, a] = hexToRgba(hex)
  const color: Color = {} as Color

  color.r = r / 255
  color.g = g / 255
  color.b = b / 255
  color.a = a

  return color
}

export function colorToHex (color: Color): string {
  const rgb = [
    Math.round(color.r * 255),
    Math.round(color.g * 255),
    Math.round(color.b * 255)
  ] as [number, number, number]

  return rgbaToHex(rgb)
}
