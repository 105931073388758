
import { UnityInstance, UnityWindow } from '@/data/unity/UnityInstance'
import { Options, Vue } from 'vue-class-component'
import { RayTracerInterface } from './ray-tracer-challenge/RayTracerInterface'
import { PatternStripe, RayTracerMaterialWrapper, RayTracerObject, RayTracerPatternUpdate, RayTracerScene, RayTracerSceneCamera, RayTracerScenePointLight } from './ray-tracer-challenge/SceneModels'
import TransformEditor from './ray-tracer-challenge/TransformEditor.vue'
import CameraEditor from './ray-tracer-challenge/CameraEditor.vue'
import PointLightEditor from './ray-tracer-challenge/PointLightEditor.vue'
import ObjectList from './ray-tracer-challenge/ObjectList.vue'
import Item from '@/components/Item.vue'
import MaterialList from './ray-tracer-challenge/MaterialList.vue'
import { debounce } from 'lodash'
import PatternList from './ray-tracer-challenge/PatternList.vue'
import { hexToColor } from './ray-tracer-challenge/Helpers'
import { SaveToLocal, GetFromLocal } from '../../data/Local'

interface SceneData {
  camera: RayTracerSceneCamera;
  pointLight: RayTracerScenePointLight;
  objects: RayTracerObject[];
  materials: RayTracerMaterialWrapper[];
  patterns: RayTracerPatternUpdate[];
  width: number;
  height: number;
}

@Options({
  components: {
    TransformEditor,
    CameraEditor,
    PointLightEditor,
    Item,
    ObjectList,
    MaterialList,
    PatternList
  }
})
export default class UnityPage extends Vue {
  rayTracer!: RayTracerInterface;
  activeTab = 0

  loading = true

  previewOn = false
  renderOn = false

  onSceneUpdatedDebounced = debounce(this.onSceneUpdated, 500)
  onPatternRenderDebounced = debounce(this.renderPattern, 1000)
  dimensionsUpdatedDebounced = debounce(this.dimensionsUpdated, 1000)

  renderWidth = 255
  renderHeight = 144

  camera: RayTracerSceneCamera = {
    fieldOfView: 60,
    position: { x: 0, y: 0, z: -5 },
    rotation: { x: 0, y: 0, z: 0 }
  }

  pointLight: RayTracerScenePointLight = {
    position: {
      x: 3,
      y: 5,
      z: 0
    },
    color: {
      r: 0.5,
      g: 0.25,
      b: 0.25,
      a: 1
    }
  }

  objects: RayTracerObject[] = [
    {
      name: 'Default Cube',
      type: 'Cube',
      object: {
        scale: {
          x: 1,
          y: 1,
          z: 1
        },
        position: {
          x: 0,
          y: 0,
          z: 0
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0
        },
        materialName: null
      }
    }
  ];

  materials: RayTracerMaterialWrapper[] = [
    {
      name: 'My First Material',
      material: {
        color: { r: 1, g: 1, b: 1, a: 1 },
        ambient: 0.1,
        diffuse: 0.9,
        specular: 0.9,
        shininess: 200.0,
        reflective: 0.0,
        transparency: 0.0,
        refractiveIndex: 1,
        patternName: null
      }
    }
  ];

  patterns: RayTracerPatternUpdate[] = [
    {
      name: 'My First Pattern',
      pattern: {
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 45, z: 0 },
        scale: { x: 0.1, y: 1, z: 1 },
        patternNames: ['#fff', '#000'],
        type: 'Stripe'
      } as PatternStripe
    }
  ];

  get availableMaterials (): string[] {
    return this.materials.map(m => m.name)
  }

  get usedMaterials (): string[] {
    return this.objects.map(o => o.object.materialName).filter(m => m) as string[]
  }

  get availablePatterns (): string[] {
    return this.patterns.map(p => p.name)
  }

  get usedPatterns (): string[] {
    return this.materials.map(m => m.material.patternName).filter(p => p) as string[]
  }

  mounted (): void {
    (window as UnityWindow).nr.onFullyLoaded = this.onFullyLoaded

    this.$watch('activeTab', () => {
      if (this.activeTab === 3) {
        this.rayTracer.showPatternRender()
        this.togglePreview(false)
      } else {
        this.rayTracer.hidePatternRender()
        this.togglePreview(true)
      }
    })
  }

  onFullyLoaded (): void {
    if (!this.rayTracer) {
      return
    }
    this.rayTracer.active = true

    const existingScene = GetFromLocal<SceneData>('RayTracerScene')

    if (existingScene) {
      this.camera = existingScene.camera
      this.pointLight = existingScene.pointLight
      this.objects = existingScene.objects
      this.materials = existingScene.materials
      this.patterns = existingScene.patterns
      this.renderWidth = existingScene.width
      this.renderHeight = existingScene.height
    }

    this.setScene()
    this.togglePreview(true)
    this.toggleRender(false)

    this.dimensionsUpdatedDebounced()

    this.loading = false
  }

  onResize (): void {
    if (!this.loading) {
      this.setScene()
    }
  }

  onCreated (unityInstance: UnityInstance): void {
    this.rayTracer = new RayTracerInterface(unityInstance)
  }

  togglePreview (newValue: boolean): void {
    this.previewOn = newValue
    if (newValue) {
      this.rayTracer.showPreview()
    } else {
      this.rayTracer.hidePreview()
    }
  }

  toggleRender (newValue: boolean): void {
    this.renderOn = newValue
    if (newValue) {
      this.rayTracer.showRender()
    } else {
      this.rayTracer.hideRender()
    }
  }

  cameraUpdated (newWidth: number, newHeight: number): void {
    this.rayTracer.setCamera(this.camera)
    this.renderWidth = newWidth
    this.renderHeight = newHeight

    this.dimensionsUpdatedDebounced()
  }

  dimensionsUpdated (): void {
    this.rayTracer.setWidth(this.renderWidth)
    this.rayTracer.setHeight(this.renderHeight);

    (window as UnityWindow).unityInstance.forceResize()
  }

  pointLightUpdated (): void {
    this.rayTracer.setPointLight(this.pointLight)
  }

  objectUpdated (index: number): void {
    const obj = this.objects[index]
    if (!obj) {
      this.setScene()
      return
    }

    this.rayTracer.updateSingleObject({
      index,
      wrapper: {
        type: obj.type,
        content: JSON.stringify(obj.object)
      }
    })
  }

  onMaterialNameChange (previous: string, newName: string): void {
    this.objects.forEach(o => {
      if (o.object.materialName === previous) {
        o.object.materialName = newName
      }
    })
    this.onSceneUpdatedDebounced()
  }

  onPatternNameChange (previous: string, newName: string): void {
    this.materials.forEach(m => {
      if (m.material.patternName === previous) {
        m.material.patternName = newName
      }
    })
    this.onSceneUpdatedDebounced()
  }

  onPatternUpdated (patternIndex: number): void {
    const patternUpdate = this.patterns[patternIndex]
    patternUpdate.pattern.patternNames.forEach(c => {
      if (c.startsWith('#')) {
        this.rayTracer.updateSingleSolidColorPattern({
          name: c,
          color: hexToColor(c)
        })
      }
    })
    this.rayTracer.updateSinglePattern(patternUpdate)
    this.onPatternRenderDebounced(patternUpdate.name)
  }

  onPatternSelected (patternIndex: number): void {
    const patternUpdate = this.patterns[patternIndex]
    this.rayTracer.renderPattern(patternUpdate.name)
  }

  renderPattern (patternName: string): void {
    this.rayTracer.renderPattern(patternName)
  }

  onSceneUpdated (): void {
    this.toggleRender(false)
    this.setScene()
  }

  setScene (): void {
    const scene: RayTracerScene = {
      camera: this.camera,
      light: this.pointLight,
      objects: this.objects.map(o => ({
        type: o.type,
        content: JSON.stringify(o.object)
      })),
      materials: {},
      patterns: {},
      solidColorPatterns: {}
    }

    this.materials.forEach(m => {
      scene.materials[m.name] = m.material
    })

    this.patterns.forEach(p => {
      scene.patterns[p.name] = p.pattern
      p.pattern.patternNames.forEach(c => {
        if (c.startsWith('#')) {
          scene.solidColorPatterns[c] = hexToColor(c)
        }
      })
    })

    this.rayTracer.setScene(scene)

    SaveToLocal<SceneData>('RayTracerScene', {
      camera: this.camera,
      pointLight: this.pointLight,
      objects: this.objects,
      materials: this.materials,
      patterns: this.patterns,
      width: this.renderWidth,
      height: this.renderHeight
    })
  }

  renderScene (): void {
    this.toggleRender(true)
    this.rayTracer.renderScene()
  }
}
