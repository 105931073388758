<template>
  <div
    class="unity-container"
    ref="unityContainer"
  >
    <div
      class="unity-desktop"
      ref="unityDesktop"
    >
      <canvas
        ref="unityCanvas"
        id="unity-canvas"
        v-shadow="16"
        v-shape
      />

      <div class="unity-controls">
        <div
          ref="loadingBar"
          class="unity-loading-bar"
          id="unity-loading-bar"
        >
          <div>
            <ui-progress
              :progress="progress"
              :buffer="1"
            />
            <figcaption>Loading (Progress: {{ (progress * 100).toFixed(0) }}%)</figcaption>
          </div>
        </div>

        <div class="inputs-popup">
          <ui-card
            style="display: none;"
            class="inputs"
            v-shadow="16"
          >
            <div :class="$tt('headline6')">
              File Upload
            </div>
            <!-- <div :class="$tt('subtitle2')">
              Upload a file
            </div> -->
            <input
              ref="fileUploadInput"
              id="unity_file-upload"
              type="file"
            >
            <input
              style="display: none"
              ref="textInput"
              id="unity_text-input"
              type="number"
            >
          </ui-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { debounce } from 'lodash'
import { Options, prop, Vue } from 'vue-class-component'
import { isOnIOS } from '@/common'
import { UnityInstance, UnityConfig, UnityWindow } from '@/data/unity/UnityInstance'

declare function createUnityInstance(
  c: HTMLCanvasElement,
  conf: UnityConfig,
  progCallback: (prog: number) => void
): Promise<UnityInstance>;

class UnityViewProps {
  buildName!: string;
  width = prop<number>({ default: 960 });
  height = prop<number>({ default: 600 });
}

@Options({ components: { } })
export default class UnityView extends Vue.with(UnityViewProps) {
  get baseUrl (): string {
    return '/unity'
  }

  enabled = true
  beginLoadingTimeout = 0
  progress = 0.0
  debouncedResize!: () => void
  isFullScreenOnIOS = false

  declare $refs: {
    unityCanvas: HTMLCanvasElement
    loadingBar: HTMLDivElement
    fullscreenButton: HTMLDivElement
    unityContainer: HTMLElement
    unityDesktop: HTMLElement
  }

  get isStillValid (): boolean {
    return Boolean(this.beginLoadingTimeout && this.$refs.unityCanvas)
  }

  mounted (): void {
    this.beginLoadingTimeout = setTimeout(() => this.loadUnityCanvas(), 500)
  }

  created (): void {
    this.debouncedResize = debounce(this.onResize, 500)
    window.addEventListener('resize', this.debouncedResize);
    (window as UnityWindow).nr = {}
  }

  deactivated (): void {
    window.removeEventListener('resize', this.debouncedResize)
    const instance = (window as UnityWindow).unityInstance as UnityInstance
    if (instance) {
      instance.Quit(() => { console.log('Unloaded unity instance') })
    }
    clearTimeout(this.beginLoadingTimeout)
    this.beginLoadingTimeout = 0
  }

  onResize (): void {
    const canvas = this.$refs.unityCanvas
    const container = this.$refs.unityContainer

    if (!canvas || !container) {
      return
    }

    if (this.isFullScreenOnIOS) {
      canvas.style.width = `${window.innerWidth}px`
      canvas.style.height = `${window.innerHeight}px`

      canvas.className = 'force-full-size'
    } else {
      const width = container.clientWidth - 100
      const height = window.innerHeight - 100

      let scale = (width / this.width)
      const heightScale = (height / this.height)

      if (scale > heightScale) {
        scale = heightScale
      }

      canvas.style.width = `${this.width * scale}px`
      canvas.style.height = `${this.height * scale}px`
    }

    this.$emit('resize')
  }

  async loadUnityCanvas (): Promise<void> {
    var buildUrl = `${this.baseUrl}/${this.buildName}`
    var loaderUrl = `${buildUrl}/_Builds.loader.js`
    const config = {
      dataUrl: buildUrl + '/_Builds.data',
      frameworkUrl: buildUrl + '/_Builds.framework.js',
      codeUrl: buildUrl + '/_Builds.wasm',
      streamingAssetsUrl: 'StreamingAssets',
      companyName: 'Niks.Games',
      productName: 'Pool Table',
      productVersion: '0.1',
      webglContextAttributes: {
        preserveDrawingBuffer: true
      }
    }

    let unityLoaderLoaded: Promise<void>

    if (!(window as UnityWindow).createUnityInstance) {
      var script = document.createElement('script')
      script.src = loaderUrl
      unityLoaderLoaded = new Promise((resolve) => {
        script.onload = () => resolve()
      })
      document.head.appendChild(script)
    } else {
      unityLoaderLoaded = Promise.resolve()
    }

    if (!this.enabled || !this.isStillValid) return

    await unityLoaderLoaded

    if (!this.isStillValid) return

    this.onResize()

    this.$refs.loadingBar.style.display = 'block'

    // config.webglContextAttributes = { preserveDrawingBuffer: true }

    await createUnityInstance(
      this.$refs.unityCanvas,
      config,
      (progress) => {
        this.progress = progress
      }
    )
      .then((unityInstance) => {
        if (!this.isStillValid) {
          unityInstance.Quit(() => { console.log('Unloaded unity instance') })
          return
        }
        this.$refs.loadingBar.style.display = 'none';

        (window as UnityWindow).unityInstance = unityInstance

        unityInstance.forceResize = () => this.onResize()
        unityInstance.switchToFullscreen = () => this.switchToFullscreen(unityInstance)

        this.$emit('created', unityInstance)
      })
      .catch((message) => {
        console.error(message)
      })

    this.debouncedResize()
  }

  switchToFullscreen (unityInstance: UnityInstance): void {
    if (isOnIOS()) {
      this.isFullScreenOnIOS = true
      if (this.$refs.unityDesktop) {
        this.$refs.unityDesktop.classList.add('force-full-size')
      }
      this.onResize()
    } else {
      unityInstance.SetFullscreen(1)
    }
  }
}

</script>

<style lang="scss" scoped>

.force-full-size {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0;
    right: 0;
    z-index: 9998;
}

.unity-container {
    position: relative;
    width: 100%;
}

.unity-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.unity-controls {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    pointer-events: none;
    top: 0;
    justify-content: center;
    align-items: center;
}

.expand-button {
  pointer-events: all;
}

.inputs-popup {
  width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 9999;
}

.inputs {
  pointer-events: all;
    margin-bottom: 1em;

  & input {
    font-size: larger;
    margin: 2em;
  }
}

.unity-loading-bar {
  padding: 0 10em;
  width: 50%;
  position: absolute;
}

</style>
