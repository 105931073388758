
import { Options, Vue } from 'vue-class-component'
import { Vector3 } from './SceneModels'

class TransformEditorProps {
  position?: Vector3;
  rotation?: Vector3;
  scale?: Vector3;
}

@Options({})
export default class TransformEditor extends Vue.with(TransformEditorProps) {
  onChange (): void {
    this.$emit('change')
  }
}
