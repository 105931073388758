<template>
  <container>
    <item>
      <ui-textfield v-model="clientName">
        Name
      </ui-textfield>
    </item>

    <item>
      <ui-textfield
        v-model="hostDescription"
        :disabled="!clientName"
      >
        Offer (from host)
      </ui-textfield>
    </item>

    <item>
      <ui-button
        @click="connectToHost()"
        :disabled="!hostDescription"
      >
        Create response
      </ui-button>
    </item>

    <item>
      <ui-textfield
        v-model="clientDescription.text"
        :disabled="!clientDescription.text"
      >
        Answer (to host)
        <template #after>
          <ui-textfield-icon v-copy="clientDescription">
            content_copy
          </ui-textfield-icon>
        </template>
      </ui-textfield>
    </item>
  </container>
</template>

<script lang="ts">

import { Vue, Options } from 'vue-class-component'
import { useToast } from 'balm-ui/plugins/toast'
import { HostOffer, WebRTCClient } from '@/data/webrtc/WebRTCClient'
import { WebRTCIceServersProvider } from '@/data/webrtc/WebRTCIceServersProvider'
import Item from '../Item.vue'

@Options({
  components: { Item }
})
export default class WebRTCClientComponent<T> extends Vue {
  iceServersProvider = new WebRTCIceServersProvider()
  client = new WebRTCClient<T>(this.iceServersProvider)

  clientName = ''

  hostOffer?: HostOffer

  clientDescription = {
    text: '',
    success: (): void => {
      useToast()('Copied client description!')
    }
  }

  hostDescription = ''

  mounted (): void {
    this.client.onConnectionOpen = () => {
      this.clientDescription.text = ''
      this.hostDescription = ''

      this.$emit('connected', this.client)
    }

    this.client.onMessage = (m) => {
      this.$emit('newMessage', m)
    }
  }

  async connectToHost (): Promise<void> {
    if (!this.hostDescription || !this.clientName) {
      return
    }
    this.hostOffer = await this.client.connectToHost(atob(this.hostDescription), this.clientName)
    this.hostOffer.localDescription.clientName = this.clientName
    this.clientDescription.text = btoa(JSON.stringify(this.hostOffer.localDescription))
  }
}
</script>

<style lang="scss" scoped>
.rtc-client {
  padding: 0.5em;
}
</style>
