export function SaveToLocal<T> (name: string, value: T): void {
  window.localStorage.setItem(name, JSON.stringify(value))
}

export function GetFromLocal<T> (name: string): T | undefined {
  const value = window.localStorage.getItem(name)
  if (value) {
    return JSON.parse(value) as T
  }
  return undefined
}
