
import { Options, Vue } from 'vue-class-component'
import { RouteRecordRaw } from 'vue-router'
import Container from './components/Container.vue'
import LoginButton from './components/LoginButton.vue'
import NetlifyIdentityWidget from 'netlify-identity-widget'

type NestedRouteRecordRaw = {
  children?: RouteRecordRaw[];
  route?: RouteRecordRaw
  name: string
}

@Options({ components: { Container, LoginButton } })
export default class App extends Vue {
  type = 1;
  title = 'Niks Games';
  openDrawer = false;

  login (): void {
    NetlifyIdentityWidget.init()
    NetlifyIdentityWidget.open('login')
  }

  get nestedRoutes (): NestedRouteRecordRaw[] {
    const allRoutes: NestedRouteRecordRaw[] = []

    this.$router.options.routes.filter(r => !r.meta?.hidden).forEach(r => {
      if (!r.meta?.parent) {
        allRoutes.push({
          route: r,
          name: r.path
        })
      } else {
        let parent = allRoutes.find(p => p.name === r.meta?.parent)
        if (!parent) {
          parent = { name: r.meta.parent as string, children: [] }
          allRoutes.push(parent)
        }

        ((parent as NestedRouteRecordRaw).children as RouteRecordRaw[]).push(r)
      }
    })

    return allRoutes
  }
}
