import { UnityInstance } from '@/data/unity/UnityInstance'
import { RayTracerPatternUpdate, RayTracerScene, RayTracerSceneCamera, RayTracerSceneObjectWrapperUpdate, RayTracerScenePointLight, RayTracerSolidColorPatternUpdate } from './SceneModels'

export class RayTracerInterface {
  unity: UnityInstance;
  active = false;

  constructor (unityInstance: UnityInstance) {
    this.unity = unityInstance
  }

  setCamera (camera: RayTracerSceneCamera): void {
    this.sendMessage('SetCamera', JSON.stringify(camera))
  }

  setPointLight (pointLight: RayTracerScenePointLight): void {
    this.sendMessage('SetPointLight', JSON.stringify(pointLight))
  }

  updateSingleObject (update: RayTracerSceneObjectWrapperUpdate): void {
    this.sendMessage('UpdateSingleObject', JSON.stringify(update))
  }

  setScene (scene: RayTracerScene): void {
    this.sendMessage('SetScene', JSON.stringify(scene))
  }

  renderScene (): void {
    this.sendMessage('RenderScene')
  }

  showRender (): void {
    this.sendMessage('ShowRender')
  }

  hideRender (): void {
    this.sendMessage('HideRender')
  }

  showPreview (): void {
    this.sendMessage('ShowPreview')
  }

  hidePreview (): void {
    this.sendMessage('HidePreview')
  }

  setCameraFOV (fieldOfView: number): void {
    this.sendMessage('SetCameraFOV', fieldOfView)
  }

  showPatternRender (): void {
    this.sendMessage('ShowPatternRender')
  }

  hidePatternRender (): void {
    this.sendMessage('HidePatternRender')
  }

  renderPattern (patternName: string): void {
    this.sendMessage('RenderPattern', patternName)
  }

  updateSinglePattern (patternUpdate: RayTracerPatternUpdate): void {
    this.sendMessage('UpdateSinglePattern', JSON.stringify(patternUpdate))
  }

  updateSingleSolidColorPattern (solidColorPatternUpdate: RayTracerSolidColorPatternUpdate): void {
    this.sendMessage('UpdateSingleSolidColorPattern', JSON.stringify(solidColorPatternUpdate))
  }

  setWidth (width: number): void {
    this.sendMessage('SetWidth', Math.floor(width))
  }

  setHeight (height: number): void {
    this.sendMessage('SetHeight', Math.floor(height))
  }

  private sendMessage (methodName: string, ...parameters: (string | number)[]): void {
    if (!this.active) { return }
    console.log(methodName, ...parameters)
    this.unity.SendMessage('UnityInterface', methodName, ...parameters)
  }
}
