
import { Options, Vue } from 'vue-class-component'
import { hexToColor, colorToHex, isValidHex } from './Helpers'

class ColorFieldProps {
  hex!: string;
  fieldId!: string;
  name!: string;
}

@Options({ components: { } })
export default class ColorField extends Vue.with(ColorFieldProps) {
  hexColor = ''
  textHexColor = ''

  created (): void {
    this.inputsUpdated()
  }

  mounted (): void {
    this.inputsUpdated()
  }

  inputsUpdated (): void {
    this.textHexColor = this.hexColor = colorToHex(hexToColor(this.hex))
  }

  onColorPickerChange (): void {
    this.textHexColor = this.hexColor
    this.$emit('updated', this.hexColor)
  }

  onTextColorChange (newValue: string): void {
    if (!isValidHex(newValue)) {
      return
    }

    this.hexColor = colorToHex(hexToColor(newValue))
    this.$emit('updated', this.hexColor)
  }

  onBlur (): void {
    if (!isValidHex(this.textHexColor)) {
      this.textHexColor = this.hexColor
    }
    this.$emit('blur', this.hexColor)
  }
}
