

import { debounce } from 'lodash'
import { Vue, Options } from 'vue-class-component'
import { ParticipantInfo } from './ChatMessage'

class ChatInputProps {
  typing!: ParticipantInfo[];
}

@Options({
  components: {
  }
})
export default class ChatInput extends Vue.with(ChatInputProps) {
  value = ''

  onChangeDebounced: (() => void) | null = null

  mounted (): void {
    this.onChangeDebounced = debounce(this.onChange, 100)
  }

  get currentlyTypingMessage (): string {
    if (!this.typing?.length) {
      return ''
    } else if (this.typing.length === 1) {
      return `${this.typing[0].name} is typing...`
    } else if (this.typing.length === 2) {
      return `${this.typing[0].name} and ${this.typing[1].name} are typing...`
    } else if (this.typing.length === 3) {
      return `${this.typing[0].name}, ${this.typing[1].name} and ${this.typing.length} other are typing...`
    } else if (this.typing.length === 4) {
      return `${this.typing[0].name}, ${this.typing[1].name} and ${this.typing.length} others are typing...`
    }

    return ''
  }

  submit (): void {
    if (!this.value) {
      return
    }

    this.$emit('sendMessage', this.value)

    this.value = ''
  }

  onChange (): void {
    this.$emit('typing')
  }
}
