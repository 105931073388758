
import { Options, Vue } from 'vue-class-component'
import { RayTracerScenePointLight } from './SceneModels'
import TransformEditor from './TransformEditor.vue'
import { hexToRgba, rgbaToHex } from 'hex-and-rgba'

class PointLightEditorProps {
  pointLight!: RayTracerScenePointLight;
}

@Options({ components: { TransformEditor } })
export default class PointLightEditor extends Vue.with(PointLightEditorProps) {
  color = '';

  mounted (): void {
    this.$watch('pointLight', () => {
      this.updateColor()
    })

    this.updateColor()
  }

  updateColor (): void {
    const rgb = [
      Math.round(this.pointLight.color.r * 255),
      Math.round(this.pointLight.color.g * 255),
      Math.round(this.pointLight.color.b * 255)
    ] as [number, number, number]

    this.color = rgbaToHex(rgb)
  }

  onColorChange (value: string): void {
    this.color = value
    const [r, g, b, a] = hexToRgba(value)
    this.pointLight.color.r = r / 255
    this.pointLight.color.g = g / 255
    this.pointLight.color.b = b / 255
    this.pointLight.color.a = a
  }

  onChange (): void {
    this.$emit('updated')
  }
}
