
import { Options, Vue } from 'vue-class-component'
import { RayTracerObject } from './SceneModels'
import ObjectEditor from './ObjectEditor.vue'

class ObjectListProps {
  objects!: RayTracerObject[];
  materials!: string[];
}

@Options({ components: { ObjectEditor } })
export default class ObjectList extends Vue.with(ObjectListProps) {
  selectedIndex = -1;

  onChange (): void {
    this.$emit('objectUpdated', this.selectedIndex)
  }

  selectionChange (index: number): void {
    if (this.selectedIndex === index) {
      this.selectedIndex = -1
    } else {
      this.selectedIndex = index
    }
  }

  removeObject (index: number): void {
    this.objects.splice(index, 1)
    this.selectedIndex = -1
    this.$emit('objectsUpdated')
  }

  addObject (): void {
    this.objects.push({
      type: 'Sphere',
      object: {
        scale: { x: 1, y: 1, z: 1 },
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
        materialName: null
      },
      name: 'New Object'
    })
    this.selectedIndex = this.objects.length - 1
    this.$emit('objectsUpdated')
  }
}
