<template>
  <unity-page
    title="Block Modelling Tool"
    subtitle="This tool lets you place blocks and export as an obj model file."
    build-name="block-modelling"
    :controls-data="[
      {
        control: 'WASD',
        action: 'Move Target Horizontally'
      },
      {
        control: 'Q / E',
        action: 'Down / Up Layer'
      },
      {
        control: 'Space',
        action: 'Place / Remove Block'
      },
      {
        control: 'Cursor Keys',
        action: 'Orbit Camera'
      },
      {
        control: 'R',
        action: 'Zoom In'
      },
      {
        control: 'F',
        action: 'Zoom Out'
      },
      {
        control: 'X',
        action: 'Previous Block'
      },
      {
        control: 'C',
        action: 'Next Block'
      }
    ]"
  >
    <ui-card-text>
      <div :class="$tt('body1')">
        You can save your current work by pressing the Save Workspace button, this will generate a json file which you can load back in later to pick up where you left off. You can upload a saved workspace by using the browser file upload control beneath the Unity component.
      </div>
    </ui-card-text>
  </unity-page>
</template>
