
import { Options, prop, Vue } from 'vue-class-component'
import { UnityInstance, UnityWindow } from '@/data/unity/UnityInstance'

export interface ControlEntry {
  control: string;
  action: string;
}

class UnityPageProps {
  buildName!: string;
  title!: string;
  subtitle?: string;
  controlsData?: ControlEntry[];
  controlsHeadings = prop<string[]>({
    default: [
      'Control',
      'Action'
    ]
  });

  controlsColumns = prop<string[]>({
    default: [
      'control',
      'action'
    ]
  });

  width = prop<number>({ default: 960 });
  height = prop<number>({ default: 600 });
}

@Options({})
export default class UnityPage extends Vue.with(UnityPageProps) {
  infoHidden = false

  switchToFullScreen (): void {
    (window as UnityWindow).unityInstance.switchToFullscreen()
  }

  hideInfo (): void {
    this.infoHidden = true
    this.forceResize()
  }

  showInfo (): void {
    this.infoHidden = false
    this.forceResize()
  }

  forceResize (): void {
    setTimeout(() => (window as UnityWindow).unityInstance.forceResize(), 500)
  }

  onResize (): void {
    this.$emit('resize')
  }

  onCreated (unityInstance: UnityInstance): void {
    this.$emit('created', unityInstance)
  }
}
