<template>
  <div v-if="obj">
    <div class="field">
      <label>Name:</label>
      <ui-textfield
        v-model.number="obj.name"
        @update:modelValue="onChange"
      >
        Name
      </ui-textfield>
    </div>

    <transform-editor
      :position="obj.object.position"
      :rotation="obj.object.rotation"
      :scale="obj.object.scale"
      @change="onChange"
    />
    <div class="field">
      <label>Type:</label>
      <ui-select
        v-model="obj.type"
        :options="typeOptions"
        @update:modelValue="onChange"
      >
        Type
      </ui-select>
    </div>
    <div
      class="field"
      v-if="materials"
    >
      <label>Material:</label>
      <ui-select
        v-model="obj.object.materialName"
        :options="materialOptions"
        @update:modelValue="materialSelectionChanged"
        :default-value="null"
        :default-label="'No material'"
      >
        Material
      </ui-select>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { RayTracerObject, RayTracerObjectType } from './SceneModels'
import TransformEditor from './TransformEditor.vue'

class ObjectEditorProps {
  obj!: RayTracerObject;
  materials!: string[];
}

@Options({ components: { TransformEditor } })
export default class ObjectEditor extends Vue.with(ObjectEditorProps) {
  typeOptions: {label: string, value: RayTracerObjectType}[] = [
    { label: 'Sphere', value: 'Sphere' },
    { label: 'Cube', value: 'Cube' },
    { label: 'Plane', value: 'Plane' }
  ];

  get materialOptions (): {label: string, value: string}[] {
    return [
      ...this.materials.map(m => ({
        label: m,
        value: m
      }))
    ]
  }

  materialSelectionChanged (): void {
    this.onChange()
  }

  onChange (): void {
    this.$emit('change')
  }
}
</script>

<style lang="scss" scoped>
.field {
  display: grid;
  margin: 1em 0em;
  grid-template-columns: 1fr 3fr;
  text-align: left;
}
</style>
