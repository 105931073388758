import { RTCSessionDescriptionWrapper } from './ExtendedRTCSessionDescription'
import { WebRTCIceServersProvider } from './WebRTCIceServersProvider'

const getChannelLabel = (id: string) => `aa55b5a4-5f08-4845-9f57-b3ba2a97d22c_${id}`

export interface ClientOffer {
  localDescription: RTCSessionDescription;
    setAnswerDescription: (description: string) => void;
}

export class WebRTCHost<T> {
  private channelLabel: string
  private channels: RTCDataChannel[] = []
  onConnectionOpen?: (channel: RTCDataChannel, clientName?: string) => void
  onConnectionClose?: (channel: RTCDataChannel, clientName?: string) => void
  onMessage?: (message: T) => void

  constructor (channelLabel: string, private iceServersProvider: WebRTCIceServersProvider) {
    this.channelLabel = getChannelLabel(channelLabel)
  }

  async createClientConnectionOffer (): Promise<ClientOffer> {
    const iceServers = await this.iceServersProvider.getIceServers()
    const clientConnection = new RTCPeerConnection({
      iceServers
    })

    let clientName: string | undefined

    const setAnswer = (desc: string) => {
      const descriptionWrapper: RTCSessionDescriptionWrapper = JSON.parse(desc)
      clientConnection.setRemoteDescription(descriptionWrapper.description)
      clientName = descriptionWrapper.clientName
    }

    const result = new Promise<ClientOffer>(resolve => {
      clientConnection.onicecandidate = function (e) {
        if (e.candidate === null && clientConnection.localDescription) {
          clientConnection.localDescription.sdp.replace('b=AS:30', 'b=AS:1638400')
          resolve({
            localDescription: clientConnection.localDescription,
            setAnswerDescription: setAnswer
          })
        }
      }
    })

    const channel = clientConnection.createDataChannel(this.channelLabel)

    channel.onopen = () => {
      if (this.onConnectionOpen) {
        this.onConnectionOpen(channel, clientName)
      }
    }

    channel.onmessage = (event) => {
      if (this.onMessage) {
        this.onMessage(JSON.parse(event.data))
      }
    }

    channel.onclose = () => {
      if (this.onConnectionClose) {
        this.onConnectionClose(channel, clientName)
      }
    }

    this.channels.push(channel)

    const description = await clientConnection.createOffer()
    clientConnection.setLocalDescription(description)

    return result
  }

  sendMessage (message: T): void {
    const messageString = JSON.stringify(message)
    this.channels.forEach(c => {
      if (c.readyState === 'open') {
        c.send(messageString)
      }
    })
  }
}
