
import { Options, Vue } from 'vue-class-component'
import { RayTracerObject, RayTracerObjectType } from './SceneModels'
import TransformEditor from './TransformEditor.vue'

class ObjectEditorProps {
  obj!: RayTracerObject;
  materials!: string[];
}

@Options({ components: { TransformEditor } })
export default class ObjectEditor extends Vue.with(ObjectEditorProps) {
  typeOptions: {label: string, value: RayTracerObjectType}[] = [
    { label: 'Sphere', value: 'Sphere' },
    { label: 'Cube', value: 'Cube' },
    { label: 'Plane', value: 'Plane' }
  ];

  get materialOptions (): {label: string, value: string}[] {
    return [
      ...this.materials.map(m => ({
        label: m,
        value: m
      }))
    ]
  }

  materialSelectionChanged (): void {
    this.onChange()
  }

  onChange (): void {
    this.$emit('change')
  }
}
