<template>
  <div class="color-field">
    <label :for="fieldId">{{ name }}:</label>
    <div class="color-picker">
      <input
        type="color"
        :id="fieldId"
        :name="name"
        v-model="hexColor"
        @update:modelValue="onColorPickerChange"
        @blur="onBlur"
      >
    </div>

    <ui-textfield
      v-model="textHexColor"
      @update:modelValue="onTextColorChange"
      @blur="onBlur"
    >
      {{ name }}
    </ui-textfield>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { hexToColor, colorToHex, isValidHex } from './Helpers'

class ColorFieldProps {
  hex!: string;
  fieldId!: string;
  name!: string;
}

@Options({ components: { } })
export default class ColorField extends Vue.with(ColorFieldProps) {
  hexColor = ''
  textHexColor = ''

  created (): void {
    this.inputsUpdated()
  }

  mounted (): void {
    this.inputsUpdated()
  }

  inputsUpdated (): void {
    this.textHexColor = this.hexColor = colorToHex(hexToColor(this.hex))
  }

  onColorPickerChange (): void {
    this.textHexColor = this.hexColor
    this.$emit('updated', this.hexColor)
  }

  onTextColorChange (newValue: string): void {
    if (!isValidHex(newValue)) {
      return
    }

    this.hexColor = colorToHex(hexToColor(newValue))
    this.$emit('updated', this.hexColor)
  }

  onBlur (): void {
    if (!isValidHex(this.textHexColor)) {
      this.textHexColor = this.hexColor
    }
    this.$emit('blur', this.hexColor)
  }
}
</script>

<style lang="scss" scoped>
.color-field {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  margin: 1em 0em;
  text-align: left;
}

.color-picker {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
