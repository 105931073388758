<template>
  <container>
    <item>
      <ui-button
        @click="createClientOffer()"
      >
        Create client offer
      </ui-button>
    </item>

    <item>
      <ui-textfield
        v-model="hostDescription.text"
        :attrs="{ readonly: true }"
        :disabled="!hostDescription.text"
      >
        Client offer
        <template #after>
          <ui-textfield-icon v-copy="hostDescription">
            content_copy
          </ui-textfield-icon>
        </template>
      </ui-textfield>
    </item>

    <item>
      <ui-textfield
        v-model="clientDescription"
        :disabled="!hostDescription.text"
      >
        Client response
      </ui-textfield>
    </item>

    <item>
      <ui-button
        @click="acceptClientConnection()"
        :disabled="!clientDescription"
      >
        Accept client
      </ui-button>
    </item>

    <item>
      <ui-button
        @click="connectToSelf()"
        :disabled="client"
      >
        Connect to self
      </ui-button>
    </item>
  </container>
</template>

<script lang="ts">

import { Vue, Options } from 'vue-class-component'
import { ClientOffer, WebRTCHost } from '@/data/webrtc/WebRTCHost'
import { useToast } from 'balm-ui/plugins/toast'
import { WebRTCIceServersProvider } from '@/data/webrtc/WebRTCIceServersProvider'
import { WebRTCClient } from '@/data/webrtc/WebRTCClient'
import Item from '../Item.vue'

@Options({ components: { Item } })
export default class WebRTCHostComponent<T> extends Vue {
  iceServersProvider = new WebRTCIceServersProvider()
  host = new WebRTCHost<T>('abc', this.iceServersProvider)

  clientOffer?: ClientOffer

  hostDescription = {
    text: '',
    success: (): void => {
      useToast()('Copied host description!')
    }
  }

  client: WebRTCClient<T> | null = null

  clientDescription = ''

  messages: T[] = []

  mounted (): void {
    this.host.onMessage = (m) => {
      this.host.sendMessage(m)
      this.messages.push(m)

      this.$emit('newMessage', m)
    }

    this.host.onConnectionOpen = (c, n) => {
      this.messages.forEach(m => {
        c.send(JSON.stringify(m))
      })

      this.$emit('clientConnected', c, n, this.host)
    }

    this.host.onConnectionClose = (c, n) => {
      this.$emit('clientDisconnected', c, n, this.host)
    }
  }

  async createClientOffer (): Promise<void> {
    this.clientOffer = await this.host.createClientConnectionOffer()
    this.hostDescription.text = btoa(JSON.stringify(this.clientOffer.localDescription))
  }

  async acceptClientConnection (): Promise<void> {
    if (this.clientOffer && this.clientDescription) {
      this.clientOffer.setAnswerDescription(atob(this.clientDescription))
      this.hostDescription.text = ''
      this.clientDescription = ''
    }
  }

  async connectToSelf (): Promise<void> {
    const selfClient = new WebRTCClient(this.iceServersProvider)
    this.client = selfClient
    this.$emit('selfClientCreated', selfClient)

    const selfOffer = await this.host.createClientConnectionOffer()
    const hostOffer = await selfClient.connectToHost(JSON.stringify(selfOffer.localDescription), 'Host')
    selfOffer.setAnswerDescription(JSON.stringify(hostOffer.localDescription))
  }
}
</script>
