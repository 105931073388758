<template>
  <div class="container__item">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.container__item {
  margin-bottom: 1em;
}
</style>
