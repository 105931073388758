<template>
  <div>
    <div
      class="currently-typing"
      :class="$tt('subtitle2')"
    >
      {{ currentlyTypingMessage }}
    </div>
    <div
      class="chat--input"
      v-shadow="16"
    >
      <ui-editor
        v-model="value"
        class="chat--input-editor"
        v-shape
        @update:modelValue="onChangeDebounced"
      />
      <ui-fab
        class="chat--input-button"
        icon="send"
        @click="submit()"
      />
    </div>
  </div>
</template>

<script lang="ts">

import { debounce } from 'lodash'
import { Vue, Options } from 'vue-class-component'
import { ParticipantInfo } from './ChatMessage'

class ChatInputProps {
  typing!: ParticipantInfo[];
}

@Options({
  components: {
  }
})
export default class ChatInput extends Vue.with(ChatInputProps) {
  value = ''

  onChangeDebounced: (() => void) | null = null

  mounted (): void {
    this.onChangeDebounced = debounce(this.onChange, 100)
  }

  get currentlyTypingMessage (): string {
    if (!this.typing?.length) {
      return ''
    } else if (this.typing.length === 1) {
      return `${this.typing[0].name} is typing...`
    } else if (this.typing.length === 2) {
      return `${this.typing[0].name} and ${this.typing[1].name} are typing...`
    } else if (this.typing.length === 3) {
      return `${this.typing[0].name}, ${this.typing[1].name} and ${this.typing.length} other are typing...`
    } else if (this.typing.length === 4) {
      return `${this.typing[0].name}, ${this.typing[1].name} and ${this.typing.length} others are typing...`
    }

    return ''
  }

  submit (): void {
    if (!this.value) {
      return
    }

    this.$emit('sendMessage', this.value)

    this.value = ''
  }

  onChange (): void {
    this.$emit('typing')
  }
}
</script>

<style lang="scss" scoped>
.currently-typing {
    padding: 0.25em;
}

  .chat--input {
    display: flex;
    align-items: flex-end;

    &-editor {
      flex-grow: 1;
    }

    &-button {
      flex-shrink: 1;
      margin: 1em;
    }
  }
</style>

<style lang="scss">
$color: whitesmoke;

$primary: #FFA500;
$secondary:#3DBAFF;
$background: #1f1f1f;
$surface:#343434;
$error:#b00020;

$border-radius: 4px;

.chat--input {
  & .ql-snow {
    color: $color !important;

    &.ql-toolbar {
      border: none;
    }

    &.ql-container {
      border: none;
    }

    & .ql-link {
      display: none;
    }

    & .ql-picker {
      display: none;
      color: $color !important;

      & .ql-stroke {
        stroke: $color !important;
      }

      & :hover {
        color: $primary !important;

        & .ql-stroke {
          stroke: $primary !important;
        }
      }

      & .ql-picker-options {
        background-color: $background;
      }
    }

    & button {
      color: $color !important;

      &:hover {
        color: $primary !important;
      }
    }

    & .ql-active {
      color: $secondary !important;
    }

  }

}
</style>
