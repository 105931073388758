<template>
  <div class="page--top-app-bar">
    <ui-top-app-bar
      content-selector="#content-main"
      :type="type"
      :title="($route?.meta.parent ? $route?.meta.parent + ' - ' : '') + $route.name"
      @nav="openDrawer = true"
    >
      <template #toolbar="{ toolbarItemClass }">
        <a href="https://github.com/nik-rob">
          <ui-icon-button :class="toolbarItemClass">
            <template #default="{ onClass, offClass }">
              <i :class="[onClass, 'fab fa-github fa-icon']" />
              <i :class="[offClass, 'fab fa-github fa-icon']" />
            </template>
          </ui-icon-button>
        </a>

        <login-button
          :class="[toolbarItemClass, 'login-button']"
        />
      </template>
    </ui-top-app-bar>

    <ui-drawer
      v-model="openDrawer"
      type="modal"
    >
      <ui-drawer-header>
        <ui-drawer-title>Nik's Games</ui-drawer-title>
      </ui-drawer-header>
      <ui-drawer-content>
        <ui-list>
          <ui-list-divider />

          <ui-nav>
            <div
              v-for="nestedRoute in nestedRoutes"
              :key="nestedRoute.name"
            >
              <router-link
                v-slot="{ href, isExactActive }"
                v-if="nestedRoute.route"
                :to="nestedRoute.route.path"
              >
                <ui-nav-item
                  :active="isExactActive"
                  :href="href"
                >
                  {{ nestedRoute.route.name }}
                </ui-nav-item>
              </router-link>

              <div
                v-if="!nestedRoute.route"
              >
                <container
                  :class="$tt('subtitle1')"
                >
                  {{ nestedRoute.name }}
                </container>

                <router-link
                  v-for="route in nestedRoute.children"
                  :key="route.path"
                  v-slot="{ href, isExactActive }"
                  :to="route.path"
                >
                  <ui-nav-item
                    :active="isExactActive"
                    :href="href"
                  >
                    {{ route.name }}
                  </ui-nav-item>
                </router-link>
              </div>
            </div>
          </ui-nav>
        </ui-list>
      </ui-drawer-content>
    </ui-drawer>

    <div id="content-main">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.app-container {
  height: 100%;
}

.mdc-linear-progress__bar-inner {
  display: block !important;
}

.mdc-icon-button__icon.fa-icon,.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on.fa-icon {
  display: block;
}

.brand-items {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 48px;

  & div {
    margin-left: 0.5em;
  }
}

.login-button {
  margin-left: 1em;
}
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { RouteRecordRaw } from 'vue-router'
import Container from './components/Container.vue'
import LoginButton from './components/LoginButton.vue'
import NetlifyIdentityWidget from 'netlify-identity-widget'

type NestedRouteRecordRaw = {
  children?: RouteRecordRaw[];
  route?: RouteRecordRaw
  name: string
}

@Options({ components: { Container, LoginButton } })
export default class App extends Vue {
  type = 1;
  title = 'Niks Games';
  openDrawer = false;

  login (): void {
    NetlifyIdentityWidget.init()
    NetlifyIdentityWidget.open('login')
  }

  get nestedRoutes (): NestedRouteRecordRaw[] {
    const allRoutes: NestedRouteRecordRaw[] = []

    this.$router.options.routes.filter(r => !r.meta?.hidden).forEach(r => {
      if (!r.meta?.parent) {
        allRoutes.push({
          route: r,
          name: r.path
        })
      } else {
        let parent = allRoutes.find(p => p.name === r.meta?.parent)
        if (!parent) {
          parent = { name: r.meta.parent as string, children: [] }
          allRoutes.push(parent)
        }

        ((parent as NestedRouteRecordRaw).children as RouteRecordRaw[]).push(r)
      }
    })

    return allRoutes
  }
}
</script>
