import { RTCSessionDescriptionWrapper } from './ExtendedRTCSessionDescription'
import { WebRTCIceServersProvider } from './WebRTCIceServersProvider'

export interface HostOffer {
  localDescription: RTCSessionDescriptionWrapper;
  setAnswerDescription: (description: string) => void;
}

export class WebRTCClient<T> {
  private channel?: RTCDataChannel;
  private iceServersProvider: WebRTCIceServersProvider;
  private _name?: string;
  onConnectionOpen?: (channel: RTCDataChannel) => void
  onMessage?: (message: T) => void

  get name (): string | undefined {
    return this._name
  }

  constructor (iceServersProvider: WebRTCIceServersProvider) {
    this.iceServersProvider = iceServersProvider
  }

  async connectToHost (hostDescription: string, clientName: string): Promise<HostOffer> {
    const iceServers = await this.iceServersProvider.getIceServers()
    const hostConnection = new RTCPeerConnection({
      iceServers
    })

    this._name = clientName

    const result = new Promise<HostOffer>(resolve => {
      hostConnection.onicecandidate = function (e) {
        if (e.candidate === null && hostConnection.localDescription) {
          hostConnection.localDescription.sdp.replace('b=AS:30', 'b=AS:1638400')
          resolve({
            localDescription: {
              description: hostConnection.localDescription,
              clientName
            },
            setAnswerDescription: (desc) => hostConnection.setRemoteDescription(JSON.parse(desc))
          })
        }
      }
    })

    hostConnection.ondatachannel = ({ channel }) => {
      this.channel = channel
      channel.onopen = () => {
        if (this.onConnectionOpen) {
          this.onConnectionOpen(channel)
        }
      }

      channel.onmessage = (event) => {
        if (this.onMessage) {
          this.onMessage(JSON.parse(event.data))
        }
      }
    }

    await hostConnection.setRemoteDescription(JSON.parse(hostDescription))
    const description = await hostConnection.createAnswer()
    hostConnection.setLocalDescription(description)

    return result
  }

  sendMessage (message: T): void {
    if (this.channel) {
      this.channel.send(JSON.stringify(message))
    }
  }
}
