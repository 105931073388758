<template>
  <div>
    <object-editor
      :obj="objects[selectedIndex]"
      @change="onChange"
      :materials="materials"
    />
    <ui-divider />
    <ui-list
      :single-selection="true"
      :model-value="selectedIndex"
      @update:modelValue="selectionChange"
    >
      <ui-item
        v-for="(obj, index) in objects"
        :key="obj"
      >
        <ui-item-text-content>
          <ui-item-text-content>{{ obj.name }}</ui-item-text-content>
        </ui-item-text-content>
        <ui-item-last-content v-if="index === selectedIndex">
          <ui-icon-button
            icon="remove_circle"
            @click="removeObject(index)"
          />
        </ui-item-last-content>
      </ui-item>
      <ui-button @click="addObject">
        Add Object
      </ui-button>
    </ui-list>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { RayTracerObject } from './SceneModels'
import ObjectEditor from './ObjectEditor.vue'

class ObjectListProps {
  objects!: RayTracerObject[];
  materials!: string[];
}

@Options({ components: { ObjectEditor } })
export default class ObjectList extends Vue.with(ObjectListProps) {
  selectedIndex = -1;

  onChange (): void {
    this.$emit('objectUpdated', this.selectedIndex)
  }

  selectionChange (index: number): void {
    if (this.selectedIndex === index) {
      this.selectedIndex = -1
    } else {
      this.selectedIndex = index
    }
  }

  removeObject (index: number): void {
    this.objects.splice(index, 1)
    this.selectedIndex = -1
    this.$emit('objectsUpdated')
  }

  addObject (): void {
    this.objects.push({
      type: 'Sphere',
      object: {
        scale: { x: 1, y: 1, z: 1 },
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
        materialName: null
      },
      name: 'New Object'
    })
    this.selectedIndex = this.objects.length - 1
    this.$emit('objectsUpdated')
  }
}
</script>
