
import { Options, Vue } from 'vue-class-component'
import { RayTracerMaterialWrapper } from './SceneModels'
import { hexToColor, colorToHex } from './Helpers'
import ColorField from './ColorField.vue'

class MaterialEditorProps {
  material!: RayTracerMaterialWrapper;
  patterns!: string[];
}

@Options({ components: { ColorField } })
export default class MaterialEditor extends Vue.with(MaterialEditorProps) {
  name = ''
  color = '';

  mounted (): void {
    this.$watch('material', () => {
      this.updateColor()
      if (this.material) {
        this.name = this.material.name
      }
    })
  }

  get patternOptions (): {label: string, value: string}[] {
    return [
      ...this.patterns.map(p => ({
        label: p,
        value: p
      }))
    ]
  }

  patternSelectionChanged (): void {
    this.onChange()
  }

  updateColor (): void {
    if (!this.material) {
      return
    }

    this.color = colorToHex(this.material.material.color)
  }

  onColorChange (value: string): void {
    this.color = value
    this.material.material.color = hexToColor(value)
  }

  onChange (): void {
    this.$emit('updated')
  }

  onNameChange (): void {
    this.$emit('nameChange', this.material.name, this.name)
    this.material.name = this.name
  }
}
